import React from 'react';
import {graphql} from "gatsby"
import NonUkResidentsBanner from '../../../components/NonUkResidents/HCP/NonUkResidentsBanner';
import Bio from '../../../components/NonUkResidents/HCP/NonResidentsHcpProfessionalsBio';
import Vision from '../../../components/NonUkResidents/HCP/ourVision'; 
import Layout from "../../../components/NonUkResidents/HCP/layout"
import queryString from 'query-string';
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryHomeTemplateNonUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulHomeTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                spaceId
                slug
                bannerSection {
                    json
                }
                missionSection {
                    json
                }
                topExcerptText {
                    json
                }
                topExcerptButtonUrl
                topExcerptImage {
                    file {
                      url
                    }
                }
                bottomExcerptText {
                    json
                }
                bottomExcerptButtonUrl
                bottomExcerptImage {
                    file {
                        url
                    }
                }
        }
    }
`

const HomeTemplatePageNonUkHcp = (props) => {
  const queryData = props.data.contentfulHomeTemplate;

  let [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {
      let {veeva} = queryString.parse(props.location.search || "");

      if(!veeva){
        setAnimate(true);
      }

  }, []);

  return ( 
    <TemplateWrapper>
        <div className={animate ? "" : "overwrite_animations"}>
    <Layout>
        <SEO title="Home | Non-UK Residents | HCP" />
      <NonUkResidentsBanner queryData={queryData} animate={animate} />
      <Bio queryData={queryData} animate={animate} />
       <Vision queryData={queryData} animate={animate} />
      
  </Layout>
  </div>
    </TemplateWrapper>
  )
}

export default HomeTemplatePageNonUkHcp